import $ from 'jquery';
import nav from './nav'; 
import calendar from './calendar'; 
import event from './event'; 
import room_overview from './room_overview'; 
import search_results from './search_results';
import slick_init from './slick_init';
import travel_description from './travel_description';
import cookie from './cookie';


const hideToggableContent = e => {
    if (window.innerWidth < 768) {
        $('.table__content').removeClass('table__content--visible');
    }
}

window.addEventListener('resize', hideToggableContent, false);

$(function() {

    $('.toggle-table-visibility-chevron').click(function(e) {
        e.preventDefault();
        $(this).parent().next('.table__content').toggleClass('table__content--visible');
        $(this).find('i.custom-icon').toggleClass('ion-chevron-down').toggleClass('ion-chevron-up');
    });

    $('.toggle-table-visibility-plus').click(function(e) {
        e.preventDefault();
        $(this).parent().next('.table__content').toggleClass('table__content--visible');
        $(this).find('i.expand').toggleClass('ion-minus').toggleClass('ion-plus');
    });
    
    $('.lecture-list').each(function() {
        // $(this).parent().next('.lecture-list li:hidden').slice(0, 2).show();
        // console.log($(this).find('li:hidden').length);
        $(this).find('li:hidden').show();
        $(this).next('.show-more').hide();
        $(this).next('.show-more').children().html('(' + $(this).find('li:hidden').length + ')');
    });


    $('.show-more').click(function(e){
        e.preventDefault();
        $(this).prev('ul').find('li:gt(1)').show();
        $(this).children().html('(' + $(this).find('li:hidden').length + ')');
    });


});



