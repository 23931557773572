import $ from 'jquery';
import 'slick-carousel';

$(function() {
    $('.hero-slider').slick({
        dots: true,
        variableWidth: false,
        centerMode: false,
        /*responsive: [
            {
                breakpoint: 768,
                settings: {
                    variableWidth: true,
                    centerMode: false,
                }
            }
        ]*/
    });

    /*window.addEventListener('resize', function(){
        $('.slick-track').each(function() {
            if ($(this).height() <= 300) {

                // $(this).find('.slick-slide').removeAttr('style');
                $('.hero-slider').slick({
                    dots: true,
                    variableWidth: false,
                    centerMode: false,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                variableWidth: false,
                                centerMode: false,
                            }
                        }
                    ]
                });
            } else {

                $('.hero-slider').slick({
                    dots: true,
                    variableWidth: false,
                    centerMode: false,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                variableWidth: true,
                                centerMode: false,
                            }
                        }
                    ]
                });

            }
        });
    });

    $(window).trigger('resize');*/

    // window.resize();

    // $('.toggable-event').hide();
    // $('.calendar__wrapper--column').click(function () {
    //     var $target = $('#div' + $(this).data('target'));
    //
    //     if (window.innerWidth < 768) {
    //         $(this).addClass('selected').siblings().removeClass('selected');
    //         $target.delay(300).slideToggle();
    //         if ($target.is(':visible')) {
    //             $(this).removeClass('selected');
    //             $target.slideUp('fast');
    //             return false;
    //         } else {
    //             $('.toggable-event:visible').not($(this)).toggle();
    //         }
    //     }
    // });


});
