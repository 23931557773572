import $ from 'jquery';

(function(){

    $('.read-more-link').on('click', function(e){
        e.preventDefault(); 
        console.log('click');
        $('.read-more-text').addClass('full-text');
    });

})();