import $ from 'jquery';

(function(){

    const cookieNotification = document.querySelector('.cookie-notification');
    const closeCookieNotification = document.querySelector('.accept-cookies');

    if(localStorage.getItem('showCookie') !== 'true') {
	    localStorage.setItem('showCookie', 'true');
        cookieNotification.style.display = 'block';
    }else if (localStorage.getItem('showCookie') === 'true') {
        cookieNotification.style.display = 'none';
    }

    const isCookiesAccepted = e => {
        e.preventDefault();
        cookieNotification.style.display = 'none';
    }

    closeCookieNotification.addEventListener('click', isCookiesAccepted, false);

})();
