//import $ from 'jquery';

(function(){

    /*
    $('.toggle-rooms').on('click', function(e){
        e.preventDefault(); 
        $(this).toggleClass('open');
    });
    */

})();