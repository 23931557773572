const showAllSearchResults = document.getElementById('show_all_search_results');
const hidedSearchResults = document.querySelectorAll('.search__results-result:nth-child(n+7)');

const isAllSearchResultsVisible = e => {
    for (var i = 0; i < hidedSearchResults.length; i++) {
        e.preventDefault();
        hidedSearchResults[i].style.display = 'block';
    }
}

if(showAllSearchResults) {
    showAllSearchResults.addEventListener('click', isAllSearchResultsVisible, false);
}