import $ from 'jquery';
import 'jquery-ui';

//$.noConflict();
$(function() {
    $('.datepicker').datepicker({
        dateFormat: 'yy-mm-dd',
        firstDay: 1,
        closeText: "Stäng",
        prevText: "Förra ",
        nextText: " Nästa",
        monthNames: [ 
            "Januari","Februari","Mars","April","Maj","Juni",
	        "Juli","Augusti","September","Oktober","November","December" 
        ],
        monthNamesShort: [ 
            "Jan","Feb","Mar","Apr","Maj","Jun",
	        "Jul","Aug","Sep","Okt","Nov","Dec" 
        ],
        dayNamesShort: [ "Sön","Mån","Tis","Ons","Tor","Fre","Lör" ],
        dayNames: [ "Söndag","Måndag","Tisdag","Onsdag","Torsdag","Fredag","Lördag" ],
        dayNamesMin: [ "Sö","Må","Ti","On","To","Fr","Lö" ]
    });
    $('.datepicker').datepicker('setDate', new Date());

    $('.datepick').click(function(e) {
        e.preventDefault();
        $('.datepicker').datepicker('show');
    });

    //Check if calendar column has more than two events
    $('.show-all-events-mobile').hide();
    $('.event__details-list').each(function(index) {
        if( $(this).children().length >= 3 ) {
    	    $(this).addClass('hided');
            $(this).next().show();
            $(this).next('.show-all-events-mobile').show();
        } else {
    	    $(this).removeClass('hided');
            $(this).next('.show-all-events-mobile').hide();
        }
    });
});

(function() {
    
    const selectWrapperBoxVisible = document.querySelector('.select__wrapper--visible');
    const selectWrapperBoxLink = document.querySelector('.select__wrapper--box-link');

    const isSelectWrapperBoxVisible = e => {
        e.preventDefault();
        selectWrapperBoxVisible.style.display = 'block';
        selectWrapperBoxLink.style.display = 'none';
    }

    if(selectWrapperBoxLink) {
        selectWrapperBoxLink.addEventListener('click', isSelectWrapperBoxVisible, false);
    }

    const triggerVisibility = document.querySelectorAll('.toggle-inner--visibility');
    const toggableIcon = document.querySelectorAll('.toggle-date');

    for (var i = 0; i < triggerVisibility.length; i++) {
        triggerVisibility[i].addEventListener('click', function() {

            $(this).find('span').toggleClass('toggle--inactive').toggleClass('toggle--active');

            if (window.innerWidth < 768) {
                this.nextElementSibling.style.display = (this.nextElementSibling.style.display != 'block' ? 'block' : '' );
            }else {
                return false;
            }
            
        });
    }

    const weekNo = document.querySelectorAll('.week-no');

    for (var i = 0; i < weekNo.length; i++) {
        if (window.innerWidth < 768) {
            weekNo[i].innerHTML = 'V 00';
        }else {
            weekNo[i].innerHTML = 'Vecka 00';
        }
    }

    const toggleWeekNoText = e => {
        for (var i = 0; i < weekNo.length; i++) {
            if (window.innerWidth < 768) {
                weekNo[i].innerHTML = 'V 00';
            }else {
                weekNo[i].innerHTML = 'Vecka 00';
            }
        }
    }   

    window.addEventListener('resize', toggleWeekNoText, false);

    $('.toggable-event').hide();
    $('.calendar__wrapper--column').click(function () {
        var $target = $('#div' + $(this).data('target'));

        if (window.innerWidth < 768) {
            $(this).addClass('selected').siblings().removeClass('selected');
            $target.delay(300).slideToggle();
            if ($target.is(':visible')) {
                $(this).removeClass('selected');
                $target.slideUp('fast');
                return false;
            } else {
                $('.toggable-event:visible').not($(this)).toggle();
            }
        }
    });

})();
