import $ from 'jquery';

(function(){
const menuContainer = document.querySelector('.menu-container');
const menuMobileisOpen = document.querySelector('.mobile-menu--open');
const menuMobileisClosed = document.querySelector('.mobile-menu--close');

const showSubMenu = document.querySelectorAll('.dropdown');
const submenu = document.querySelector('.submenu');

$('.dropdown').click(function(e) {
    e.preventDefault();
    $(this).next('.submenu').toggleClass('submenu--toggle');
});

const isMenuHided = e => {
    if (window.innerWidth > 991) {
        $('.menu-container').css('display', 'block');
    }else {
        $('.menu-container').css('display', 'none');
    }
}

window.addEventListener('resize', isMenuHided, false);

const showMenu = e => {
    menuContainer.style.display = "block";
}

menuMobileisOpen.addEventListener('click', showMenu, false);

const hideMenu = e => {
    menuContainer.style.display = "none";
    $('.dropdown').next('.submenu').removeClass('submenu--toggle');
    //submenu.classList.toggle('submenu--toggle');
}

menuMobileisClosed.addEventListener('click', hideMenu, false);

$(document).mouseup(function(e) {
    if (window.innerWidth < 991) {
    var container = $('.menu-container');

    if (!container.is(e.target) // if the target of the click isn't the container...
        && container.has(e.target).length === 0) // ... nor a descendant of the container
    {
        container.hide();
    }
    }
});

})();